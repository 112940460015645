import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Flex,
  FormControl,
  Input,
  Select,
  FormLabel,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CloseIcon } from "@chakra-ui/icons";
import { CreateProductRequest } from "generated-client/model/create-product-request";
import { attachProductImage, updateProduct } from "services/product";
import { SupplierDto } from "generated-client/model/supplier-dto";
import { useTranslation } from "react-i18next";
import { uploadFile } from "services/file";

interface EditProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  suppliers: SupplierDto[];
  supplierId: string;
  name: string;
  price: number;
  id: string;
  updateRefreshStatus: (status: boolean) => void;
}

const EditProductModal: React.FC<EditProductModalProps> = ({
  onClose,
  isOpen,
  suppliers,
  supplierId,
  name,
  price,
  id,
  updateRefreshStatus,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const fileInputRef = useRef(null);

  const [productPayload, setProductPayload] = useState<CreateProductRequest>({
    name,
    stockAvailability: "IN_STOCK",
    supplierId,
    price,
    minimumOrderQuantity: 1,
  });
  const [error, setError] = useState<string | null>(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [file, setFile] = useState<File>();

  const handleValueChange = (
    key: keyof CreateProductRequest,
    value: string
  ) => {
    if (key === "minimumOrderQuantity" || key === "price") {
      const numericValue = value.replace(/\D/g, "");
      setProductPayload((prevPayload) => ({
        ...prevPayload,
        [key]: parseInt(numericValue),
      }));
      return;
    }

    setProductPayload((prevPayload) => ({
      ...prevPayload,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {
    setError(null);
    if (!productPayload.name.trim()) {
      setError("Product name cannot be empty");
      return;
    }

    if (!productPayload.supplierId) {
      setError("Please select a supplier");
      return;
    }

    setIsSubmitLoading(true);

    try {
      const accessToken = await getAccessTokenSilently();

      await updateProduct(accessToken, {
        ...productPayload,
        price: Number(productPayload.price),
        minimumOrderQuantity: Number(productPayload.minimumOrderQuantity),
        id,
      });

      if (file) {
        const fileUploaded = await uploadFile(
          accessToken,
          {
            productId: id,
            type: "PRODUCT",
          },
          file
        );

        await attachProductImage(accessToken, {
          id,
          fileId: fileUploaded.id,
        });

        onClose();
        setProductPayload({
          name: "",
          stockAvailability: "IN_STOCK",
          supplierId: "",
        });
        setFile(null);
        setIsFileUploaded(false);
        updateRefreshStatus(true);
      }
    } catch (error) {
      console.error("Error creating product:", error);
      setError(
        "An error occurred while creating the product. Please try again."
      );
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setIsFileUploaded(true);
    setFile(file);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="space-between" alignItems="center">
            <Text>Update Product</Text>
            <CloseIcon onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <FormControl mb={3}>
            <FormLabel>Supplier name</FormLabel>
            <Select
              placeholder={t("select_supplier")}
              onChange={(e) => handleValueChange("supplierId", e.target.value)}
              w="100%"
              defaultValue={productPayload?.supplierId}
            >
              {suppliers.map((supplier) => (
                <option key={supplier.id} value={supplier.id}>
                  {supplier.friendlyName}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Product name</FormLabel>
            <Input
              value={productPayload.name}
              onChange={(e) => handleValueChange("name", e.target.value)}
            />
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Price</FormLabel>
            <Input
              value={
                productPayload.price
                  ? productPayload.price.toLocaleString()
                  : ""
              }
              onChange={(e) => handleValueChange("price", e.target.value)}
              pattern="[0-9]*"
            />
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Minimum order quantity</FormLabel>
            <Input
              value={
                productPayload.minimumOrderQuantity
                  ? productPayload.minimumOrderQuantity.toLocaleString()
                  : ""
              }
              onChange={(e) =>
                handleValueChange("minimumOrderQuantity", e.target.value)
              }
              pattern="[0-9]*"
            />
          </FormControl>

          <FormControl>
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileChange}
              accept="image/*"
            />
            <Button
              onClick={() => triggerFileInput()}
              hidden={isSubmitLoading}
              mr="3px"
              color="white"
              colorScheme="black"
              bg="#1a1a1a"
              borderRadius="4px"
            >
              {isFileUploaded ? "✅" : ""} Upload product photo
            </Button>
          </FormControl>

          {error && (
            <Text color="red.500" mt={2}>
              {error}
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="center">
            <Button
              color="white"
              colorScheme="black"
              bg="#1a1a1a"
              onClick={handleSubmit}
              isLoading={isSubmitLoading}
              disabled={isSubmitLoading}
              borderRadius="4px"
              width="100%"
            >
              Update
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditProductModal;
