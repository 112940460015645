import { callGet, callPost, callUpdate } from "../../lib/api-private";
import { CreateSourcingRequestResponse } from "generated-client/model/create-sourcing-request-response";
import { CreateSourceRequestRequest } from "generated-client/model/create-source-request-request";
import { GetAllSourcingRequestsRequest } from "generated-client/model/get-all-sourcing-requests-request";
import { GetSourcingRequestsResponse } from "generated-client/model/get-sourcing-requests-response";
import { UpdateSourcingRequestResponse } from "generated-client/model/update-sourcing-request-response";
import { UpdateSourcingRequestRequest } from "generated-client/model/update-sourcing-request-request";
import { CreateSourcingRequestProFormaInvoiceRequest } from "generated-client/model/create-sourcing-request-pro-forma-invoice-request";
import { saveAs } from "file-saver";

export const createSourcingRequest = async (
  accessToken: string,
  data: CreateSourceRequestRequest
): Promise<CreateSourcingRequestResponse> => {
  return callPost(accessToken, "/sourcingRequest", data);
};

export const getAllSourcingRequestsAdmin = async (
  accessToken: string,
  data: GetAllSourcingRequestsRequest
): Promise<GetSourcingRequestsResponse> => {
  return callPost(accessToken, "/sourcingRequest/all", data);
};

export const downloadProFormaInvoice = async (
  sourcingRequestId: string,
  proformaInvoiceId: string,
  accessToken: string
) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL +
        "/sourcingRequest/" +
        sourcingRequestId +
        "/proFormaInvoice/" +
        proformaInvoiceId,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const blob = await response.blob();

    const contentDisposition = response.headers.get("Content-Disposition");

    const filename = contentDisposition
      ? contentDisposition.split("filename=")[1]
      : "file.pdf";
    saveAs(blob, filename);
  } catch (error) {
    console.error("There has been a problem with your fetch operation:", error);
  }
};
export const updateSourcingRequestStatusAdmin = async (
  accessToken: string,
  data: UpdateSourcingRequestRequest
): Promise<UpdateSourcingRequestResponse> => {
  return await callUpdate(accessToken, "/sourcingRequest", data);
};

export const createProFormaInvoice = async (
  accessToken: string,
  data: CreateSourcingRequestProFormaInvoiceRequest
): Promise<{ sourcingRequestProFormaInvoiceId: string }> => {
  return callPost(accessToken, "/sourcingRequest/proFormaInvoice", data);
};

export const getAllSourcingRequestsByCompanyId = async (
  accessToken: string
): Promise<GetSourcingRequestsResponse> => {
  return callGet(accessToken, "/sourcingRequest");
};
