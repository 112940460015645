import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import TableComponent from "./components/Table";
import { ExchangeRateDto } from "../../../generated-client/model/exchange-rate-dto";
import {
  getExchangeRates,
  updateExchangeRateAdmin,
} from "../../../services/finance";

export default function Finance() {
  const [exchangeRates, setExchangeRates] = useState<ExchangeRateDto[]>([]);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string>("");

  async function fetchData() {
    try {
      const accessToken = await getAccessTokenSilently();
      setAccessToken(accessToken);
      const data = await getExchangeRates(accessToken);

      setExchangeRates(data.exchangeRates);
    } catch (error) {
      console.error("Error fetching payment requests:", error);
    }
  }

  async function handleRateChange(rateId: string, newRate: number) {
    try {
      await updateExchangeRateAdmin(accessToken, {
        id: rateId,
        value: newRate,
      });

      setTimeout(() => {
        fetchData();
      }, 500);
    } catch (error) {
      console.error("Error fetching payment requests:", error);
    }
  }

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isAuthenticated) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  return (
    <>
      <TableComponent
        rates={exchangeRates}
        handleRateChange={handleRateChange}
      />
    </>
  );
}
