import { Flex, FormLabel, Stack, Text } from "@chakra-ui/react";
import { formatCurrency } from "../../../../lib/utilities";
import React from "react";
import { HSeparator } from "../../../../components/separator/Separator";

interface RepaymentTermsProps {
  paymentAmount: number;
  transactionFee: number;
  totalRepay: number;
  balanceToPayNow: number;
  currency: string;
  dueDate: string;
}

export const RepaymentTerms: React.FC<RepaymentTermsProps> = ({
  dueDate,
  paymentAmount,
  transactionFee,
  totalRepay,
  currency,
  balanceToPayNow,
}) => {
  return (
    <Stack spacing={1} w={["100%", "100%", "100%", "130%"]}>
      <Flex justify="space-between" mb={1}>
        <FormLabel>Payment amount</FormLabel>
        <Text>
          {currency} {formatCurrency(paymentAmount)}
        </Text>
      </Flex>
      <Flex justify="space-between" mb={1}>
        <FormLabel>Transaction fee</FormLabel>
        <Text flex="1" textAlign="right">
          {currency} {formatCurrency(transactionFee)}
        </Text>
      </Flex>
      <Flex justify="space-between" mb={0}>
        <FormLabel fontWeight="bold">Total amount to repay</FormLabel>
        <Text fontWeight="bold">
          {currency} {formatCurrency(totalRepay)}
        </Text>
      </Flex>
      <Flex justify="space-between">
        <FormLabel fontWeight="bold">Due date</FormLabel>
        <Text fontWeight="bold">{dueDate}</Text>
      </Flex>
      <HSeparator mb="5px" mt="5px" />
      <Flex justify="space-between">
        <FormLabel fontWeight="bold">Balance to pay now</FormLabel>
        <Text fontWeight="bold">
          {currency} {formatCurrency(balanceToPayNow)}
        </Text>
      </Flex>
    </Stack>
  );
};
