import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Image,
  Input,
  Text,
  VStack,
  Icon,
  Divider,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { ProductDto } from "generated-client/model/product-dto";
import { getAllProductsAdmin } from "services/product";
import { FaShieldAlt } from "react-icons/fa";
import { CloseIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

interface CartItem extends ProductDto {
  quantity: number;
}

const CART_STORAGE_KEY = "yolu-cart";
const SHIPPING_COST = 20000;

const Shop = () => {
  const [products, setProducts] = useState<ProductDto[]>([]);
  const [cart, setCart] = useState<CartItem[]>([]);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const savedCart = localStorage.getItem(CART_STORAGE_KEY);
    if (savedCart) {
      try {
        const parsedCart = JSON.parse(savedCart);
        setCart(parsedCart);
      } catch (error) {
        console.error("Error parsing cart from localStorage:", error);
        localStorage.removeItem(CART_STORAGE_KEY);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(cart));
  }, [cart]);

  const fetchProducts = async () => {
    const accessToken = await getAccessTokenSilently();
    const result = await getAllProductsAdmin(accessToken, {
      skip: 0,
      take: 20,
    });
    setProducts(result.products);
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOrder = (product: ProductDto) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((item) => item.id === product.id);
      if (existingItem) {
        return prevCart.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }
      return [
        ...prevCart,
        { ...product, quantity: product.minimumOrderQuantity || 1 },
      ];
    });
  };

  const handleQuantityChange = (productId: string, newQuantity: number) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === productId ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  const handleRemoveFromCart = (productId: string) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== productId));
  };

  const totalAmount = cart.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0
  );

  const ReviewModal = () => (
    <Modal
      isOpen={isReviewModalOpen}
      onClose={() => setIsReviewModalOpen(false)}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Review order</ModalHeader>
        <ModalBody>
          <VStack align="start" spacing={4}>
            {cart.map((item) => (
              <Text key={item.id}>
                {item.name}: {item.quantity} * RWF{" "}
                {item.price?.toLocaleString()} = RWF{" "}
                {(item.price * item.quantity).toLocaleString()}
              </Text>
            ))}

            <Text fontWeight="bold" mt={4}>
              Payment details
            </Text>
            <Flex justify="space-between" width="100%">
              <Text>Order amount</Text>
              <Text>RWF {totalAmount.toLocaleString()}</Text>
            </Flex>
            <Flex justify="space-between" width="100%">
              <Text>Shipping estimate</Text>
              <Text>RWF {SHIPPING_COST.toLocaleString()}</Text>
            </Flex>
            <Flex justify="space-between" width="100%" fontWeight="bold">
              <Text>Total amount</Text>
              <Text>RWF {(totalAmount + SHIPPING_COST).toLocaleString()}</Text>
            </Flex>

            <Box mt={4}>
              <Text>Estimated arrival date: September 30, 2024</Text>
              <Text fontSize="sm" color="gray.600">
                Orders will be shipped to our office at XXX Kigali, Rwanda for
                you to pick up.
              </Text>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            mr={3}
            onClick={() => setIsReviewModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            colorScheme="black"
            bg="black"
            color="white"
            onClick={() => {
              setIsReviewModalOpen(false);
              navigate("/dashboard/payment", {
                state: {
                  totalAmount: totalAmount + SHIPPING_COST,
                  orderAmount: totalAmount,
                  shippingCost: SHIPPING_COST,
                  cart,
                },
              });
            }}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  return (
    <Box bg="white" minH="100vh">
      <Box maxW="1400px" mx="auto" pt={8}>
        <Text fontSize="2xl" fontWeight="bold" mb={6} pl={0}>
          Shop
        </Text>
        <Grid templateColumns={{ base: "1fr", lg: "1fr 400px" }} gap={8} px={0}>
          {/* Products Section */}
          <VStack align="stretch" spacing={6}>
            {products.map((product) => (
              <Card key={product.id} p={6} variant="outline" bg="transparent">
                <Flex gap={4}>
                  <Box width="300px" height="300px" flexShrink={0}>
                    <Image
                      src={`https://uploaded-files-yolu.s3.eu-west-2.amazonaws.com/${product.files[0]?.key}`}
                      alt={product.name}
                      objectFit="contain"
                      height="100%"
                      width="100%"
                    />
                  </Box>
                  <Box>
                    <Text fontSize="2xl" fontWeight="medium" mb={2}>
                      {product.name}
                    </Text>
                    <Text color="gray.600" mb={4}>
                      Supplier: {product.supplier.friendlyName}
                    </Text>
                    <Text color="gray.600" fontSize="sm" mb={1}>
                      RWF
                    </Text>
                    <Text fontSize="2xl" fontWeight="bold" mb={4}>
                      {product.price?.toLocaleString()}
                    </Text>
                    <Text fontSize="sm" color="gray.600" mb={4}>
                      Minimum order: {product.minimumOrderQuantity}
                    </Text>
                    <Flex color="green.600" align="center" gap={2} mb={6}>
                      <Icon as={FaShieldAlt} />
                      <Text fontSize="sm">
                        Sourced from a trusted supplier. If your goods don't
                        arrive, we refund you in full.
                      </Text>
                    </Flex>
                    <Button
                      width="32"
                      color="white"
                      bg="#1a1a1a"
                      // _hover={{ bg: "#F7CA00", color: "black" }}
                      onClick={() => handleOrder(product)}
                    >
                      Order
                    </Button>
                  </Box>
                </Flex>
              </Card>
            ))}
          </VStack>

          {/* Cart Section */}
          {cart.length > 0 && (
            <Card variant="outline" p={6} bg="transparent" height="fit-content">
              <VStack align="stretch" spacing={6}>
                <Text fontSize="xl" fontWeight="bold">
                  Your order
                </Text>
                <VStack align="stretch" spacing={6}>
                  {cart.map((item) => (
                    <Box key={item.id}>
                      <Flex justify="space-between" align="center" mb={4}>
                        <Text fontWeight="medium">{item.name}</Text>
                        <IconButton
                          aria-label="Remove item"
                          icon={<CloseIcon />}
                          size="sm"
                          variant="ghost"
                          onClick={() => handleRemoveFromCart(item.id)}
                        />
                      </Flex>
                      <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                        <Box>
                          <Text fontSize="sm" color="gray.600" mb={2}>
                            Quantity
                          </Text>
                          <Input
                            type="number"
                            value={item.quantity}
                            min={item.minimumOrderQuantity || 1}
                            onChange={(e) =>
                              handleQuantityChange(
                                item.id,
                                parseInt(e.target.value) ||
                                  item.minimumOrderQuantity ||
                                  1
                              )
                            }
                            size="sm"
                          />
                        </Box>
                        <Box>
                          <Text fontSize="sm" color="gray.600" mb={2}>
                            Price
                          </Text>
                          <Text>{item.price?.toLocaleString()}</Text>
                        </Box>
                        <Box>
                          <Text fontSize="sm" color="gray.600" mb={2}>
                            Total
                          </Text>
                          <Text>
                            {(item.price * item.quantity).toLocaleString()}
                          </Text>
                        </Box>
                      </Grid>
                    </Box>
                  ))}
                </VStack>

                <Divider />
                <Flex justify="space-between" align="center">
                  <Text fontSize="lg" fontWeight="bold">
                    Total amount
                  </Text>
                  <Text fontSize="lg" fontWeight="bold">
                    RWF {totalAmount.toLocaleString()}
                  </Text>
                </Flex>
                <Button
                  size="lg"
                  width="100%"
                  color="white"
                  bg="#1a1a1a"
                  // _hover={{ bg: "#F7CA00", color: "black" }}
                  onClick={() => setIsReviewModalOpen(true)}
                >
                  Place order
                </Button>
              </VStack>
            </Card>
          )}
        </Grid>
      </Box>

      <ReviewModal />
    </Box>
  );
};

export default Shop;
