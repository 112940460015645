/**
 * Yolu API
 * Yolu backend API description 
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserDto { 
    email: string;
    phoneNumber: string;
    role: UserDto.RoleEnum;
}
export namespace UserDto {
    export type RoleEnum = 'ADMIN' | 'USER';
    export const RoleEnum = {
        Admin: 'ADMIN' as RoleEnum,
        User: 'USER' as RoleEnum
    };
}


