import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdHome,
  MdPayments,
  MdHistory,
  MdOutlineCurrencyExchange,
  MdSquare,
  MdArrowUpward,
  MdOutlineShoppingCart,
} from "react-icons/md";

// Dashboard Imports
import MainDashboard from "views/dashboard/default";
// import PaymentRequestPage from "views/dashboard/paymentRequest";
import Profile from "views/dashboard/profile";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import MyPaymentRequestsPage from "./views/dashboard/myPaymentRequests";
import AllPaymentRequests from "./views/admin/default";
import RepaymentAccountDetails from "./views/dashboard/repaymentAccountDetails";
import MyUsdPaymentsPage from "./views/dashboard/myDirectPayments";
import UsdPaymentsPage from "./views/dashboard/directPayment";
import AllDirectPayments from "./views/admin/directPayments";
import Kyc from "./views/dashboard/kyc";
import Companies from "./views/admin/companies";
import Finance from "./views/admin/finance";
import HelpCenter from "./views/dashboard/helpCenter";
import Suppliers from "./views/admin/suppliers";
import Products from "views/admin/products";
import SourcingRequests from "views/admin/sourcing";
import InternationalTransferPage from "./views/dashboard/internationalTransfer";
import Shop from "views/dashboard/shop";
import PaymentPage from "views/dashboard/shop/payment";

const routes = [
  {
    name: "Home",
    layout: "/dashboard",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Company details",
    layout: "/dashboard",
    path: "/profile",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
    hidden: true,
  },
  {
    name: "Company documents",
    layout: "/dashboard",
    path: "/kyc",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Kyc,
    hidden: true,
  },
  {
    name: "Repayment details",
    layout: "/dashboard",
    path: "/repaymentAccountDetails",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: RepaymentAccountDetails,
    hidden: true,
  },
  {
    name: "Help Center",
    layout: "/dashboard",
    path: "/helpCenter",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: HelpCenter,
    hidden: true,
  },
  {
    name: "Source",
    layout: "/dashboard",
    path: "/usdPayments",
    icon: <Icon as={MdHistory} width="20px" height="20px" color="inherit" />,
    component: UsdPaymentsPage,
    hidden: false,
  },
  {
    name: "Transfers",
    layout: "/dashboard",
    path: "/internationalTransfer",
    icon: <Icon as={MdHistory} width="20px" height="20px" color="inherit" />,
    component: InternationalTransferPage,
    hidden: true,
  },
  {
    name: "Payment",
    layout: "/dashboard",
    path: "/payment",
    icon: <Icon as={MdHistory} width="20px" height="20px" color="inherit" />,
    component: PaymentPage,
    hidden: true,
  },
  // {
  //   name: "Order",
  //   layout: "/dashboard",
  //   path: "/paymentRequest",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   component: PaymentRequestPage,
  //   hidden: false,
  // },
  {
    name: "Shop",
    layout: "/dashboard",
    path: "/shop",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Shop,
    hidden: false,
  },
  {
    name: "Local",
    layout: "/dashboard",
    path: "/myPaymentRequests",
    icon: <Icon as={MdHistory} width="20px" height="20px" color="inherit" />,
    component: MyPaymentRequestsPage,
    hidden: true,
  },
  {
    name: "International",
    layout: "/dashboard",
    path: "/myUsdPayments",
    icon: <Icon as={MdHistory} width="20px" height="20px" color="inherit" />,
    component: MyUsdPaymentsPage,
    hidden: true,
  },
  {
    name: "My transactions",
    layout: "/dashboard",
    path: "/myPaymentRequests",
    icon: <Icon as={MdHistory} width="20px" height="20px" color="inherit" />,
    component: MyPaymentRequestsPage,
  },
  {
    name: "Log in",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    hidden: true,
  },
  {
    name: "Flex pay transactions",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdPayments} width="20px" height="20px" color="inherit" />,
    component: AllPaymentRequests,
    hidden: false,
    admin: true,
  },
  {
    name: "International",
    layout: "/admin",
    path: "/directPayments",
    icon: (
      <Icon
        as={MdOutlineCurrencyExchange}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: AllDirectPayments,
    hidden: false,
    admin: true,
  },
  {
    name: "Companies",
    layout: "/admin",
    path: "/companies",
    icon: <Icon as={MdSquare} width="20px" height="20px" color="inherit" />,
    component: Companies,
    hidden: false,
    admin: true,
  },
  {
    name: "Exchange rates",
    layout: "/admin",
    path: "/exchangeRates",
    icon: (
      <Icon as={MdArrowUpward} width="20px" height="20px" color="inherit" />
    ),
    component: Finance,
    hidden: false,
    admin: true,
  },
  {
    name: "Suppliers",
    layout: "/admin",
    path: "/suppliers",
    icon: <Icon as={MdSquare} width="20px" height="20px" color="inherit" />,
    component: Suppliers,
    hidden: false,
    admin: true,
  },
  {
    name: "Featured products",
    layout: "/admin",
    path: "/products",
    icon: <Icon as={MdSquare} width="20px" height="20px" color="inherit" />,
    component: Products,
    hidden: false,
    admin: true,
  },
  {
    name: "Sourcing",
    layout: "/admin",
    path: "/sourcing?",
    icon: <Icon as={MdSquare} width="20px" height="20px" color="inherit" />,
    component: SourcingRequests,
    hidden: false,
    admin: true,
  },
];

export default routes;
