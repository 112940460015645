import { Box, Stack, Text, Button, Flex } from "@chakra-ui/react";

type Props = {
  id: string;
  name: string;
  price: number;
  features: any[];
  isLoading: boolean;
  onPlanSelect: (id: string, name: string) => void;
};

const PricingBox: React.FC<Props> = ({
  id,
  name,
  price,
  features,
  isLoading,
  onPlanSelect,
}) => {
  return (
    <Box
      bg="gray.200"
      p={8}
      borderRadius="md"
      position="relative"
      height="550px"
      width="320px"
      display="flex"
      flexDirection="column"
    >
      <Stack spacing={6} flex="1">
        <Flex
          borderBottom="2px solid"
          borderColor="purple.500"
          pb={2}
          justify="space-between"
          align="center"
        >
          <Text fontSize="xl" fontWeight="bold">
            {name}
          </Text>
          <Text fontSize="xl" ml="auto">
            {`${price > 0 ? "$" + price + "/month" : "Free"}`}
          </Text>
        </Flex>

        <Stack spacing={4}>
          <Text fontSize="lg">{features[0]}</Text>

          {features.slice(1).map((feature, index) => (
            <Flex key={index} align="center">
              <Text>{feature}</Text>
            </Flex>
          ))}
        </Stack>

        <Box position="absolute" bottom={0} left={0} right={0} p={8}>
          <Text fontSize="xl" fontWeight="bold" mb={4} textAlign="center">
            {price > 0 ? "Enjoy your first month free" : ""}
          </Text>
          <Button
            color="white"
            colorScheme="black"
            bg="#1a1a1a"
            variant="solid"
            size="md"
            width="100%"
            display="flex"
            justifyContent="center"
            onClick={() => onPlanSelect(id, name)}
            isLoading={isLoading}
            borderRadius="0.2rem"
          >
            Get started
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default PricingBox;
