/**
 * Yolu API
 * Yolu backend API description 
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateSupplierRequest { 
    name: string;
    friendlyName: string;
    bankName?: string | null;
    accountNumber?: string | null;
    mobilePayNumber?: string | null;
    mobilePayAccountName?: string | null;
    whatsappContact?: string | null;
    country: string;
    address?: string | null;
    companyRegistrationDate?: string | null;
    managingDirector?: string | null;
    email?: string | null;
    category?: CreateSupplierRequest.CategoryEnum;
    mobileNumber?: string | null;
}
export namespace CreateSupplierRequest {
    export type CategoryEnum = 'FOOD_AND_BEVERAGE' | 'CONSTRUCTION_MATERIALS' | 'ELECTRONICS';
    export const CategoryEnum = {
        FoodAndBeverage: 'FOOD_AND_BEVERAGE' as CategoryEnum,
        ConstructionMaterials: 'CONSTRUCTION_MATERIALS' as CategoryEnum,
        Electronics: 'ELECTRONICS' as CategoryEnum
    };
}


