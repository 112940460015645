import { Text, Box } from "@chakra-ui/react";
import Card from "components/card/Card";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export default function HelpCenter() {
  const { isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (isLoading) {
      return;
    }
  }, [isLoading, isAuthenticated]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        w={{ base: "100%", md: "50%" }}
        mb={{ base: "0px", "2xl": "20px" }}
        gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
        minH="150px"
        pe="20px"
      >
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          Help Center
        </Text>
        <Text>
          For customer support contact us at{" "}
          <a href="mailto:support@goyolu.com">support@goyolu.com</a>
        </Text>
      </Card>
    </Box>
  );
}
