import {
  Box,
  Flex,
  HStack,
  Icon,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { formatCurrency } from "../../../../lib/utilities";
import { PaymentRequestDtoWithOtherData } from "../../../../generated-client/model/payment-request-dto-with-other-data";
import { PaymentRequestDto } from "../../../../generated-client/model/payment-request-dto";
import StatusEnum = PaymentRequestDto.StatusEnum;
import { FaCalendarAlt } from "react-icons/fa";
import { FileDto } from "../../../../generated-client/model/file-dto";
import TypeEnum = FileDto.TypeEnum;

const payNowStatuses: StatusEnum[] = [
  "PAYMENT_SUBMITTED",
  "DECLINED",
  "PENDING",
];

interface TableComponentProps {
  paymentRequests: PaymentRequestDtoWithOtherData[];
  handleStatusChange: (
    request: PaymentRequestDtoWithOtherData,
    newStatus: string
  ) => void;
  handleOpenModal: (request: PaymentRequestDtoWithOtherData) => void;
  errorMessages: { [requestId: string]: string };
  loadingStates: { [key: string]: boolean };
  downloadFile: (id: string, requestId: string) => void;
  handlePurchaseOrderDownload: (
    request: PaymentRequestDtoWithOtherData
  ) => void;
}

export default function CardLayout({
  paymentRequests,
  loadingStates,
  downloadFile,
  handlePurchaseOrderDownload,
  handleStatusChange,
  handleOpenModal,
  errorMessages,
}: TableComponentProps) {
  return (
    <Box pt={{ base: "50px", md: "30px", xl: "30px" }}>
      {paymentRequests?.map((request) => (
        <Box
          key={request.id}
          bg="white"
          p={5}
          shadow="md"
          borderWidth="1px"
          mb={4}
        >
          <Text fontSize="xl">{request.company?.name}</Text>
          <Text mt={2} mb={4}>
            Recipient: {request.supplierName}
          </Text>

          {/* Info section */}
          <Box mb={4} fontSize="0.9em">
            <Text>
              <b>
                {request.amount === request.amountCredit
                  ? "FLEX PAY"
                  : request.amountCredit === 0
                  ? "PAY NOW"
                  : "PARTIAL FLEX"}{" "}
                - {request.paymentMethod}
              </b>
            </Text>
            <Text>
              Amount:{" "}
              {request.amountCurrency + " " + formatCurrency(request.amount)}
            </Text>
            {request.amount - request.amountCredit > 0 && (
              <Text>
                Pay now:{" "}
                {request.amountCurrency +
                  " " +
                  formatCurrency(request.amount - request.amountCredit)}
              </Text>
            )}
            {request.amountCredit > 0 && (
              <>
                <br />
                <Text>
                  Flex pay:{" "}
                  {request.amountCreditCurrency +
                    " " +
                    formatCurrency(request.amountCredit)}
                </Text>
                <Text>
                  To repay:{" "}
                  {request.amountToRepayCurrency +
                    " " +
                    formatCurrency(request.amountToRepay)}
                </Text>
                <Text>
                  Fee:{" "}
                  {request.amountToRepayCurrency +
                    " " +
                    formatCurrency(
                      request.amountToRepay - request.amountCredit
                    )}
                </Text>
              </>
            )}
            <Text>
              Received: <br /> <Icon w="9px" h="14px" as={FaCalendarAlt} />{" "}
              {new Date(request.createdAt).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
              <br />
              <br />
              {request.type === "FLEX_PAY" && (
                <>
                  Due date: <br /> <Icon w="9px" h="14px" as={FaCalendarAlt} />{" "}
                  {new Date(request.dueDate).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </>
              )}
            </Text>
            <VStack spacing="5px">
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>
                      {request.instalments?.length > 0 && (
                        <HStack
                          w="full"
                          borderBottom="1px solid"
                          borderColor="gray.200"
                        ></HStack>
                      )}
                    </Td>
                    <Td>
                      {request.instalments?.length > 0 && (
                        <HStack
                          w="full"
                          borderBottom="1px solid"
                          borderColor="gray.200"
                        ></HStack>
                      )}
                    </Td>
                    <Td>
                      {request.instalments?.length > 0 && (
                        <HStack
                          w="full"
                          borderBottom="1px solid"
                          borderColor="gray.200"
                        ></HStack>
                      )}
                    </Td>
                  </Tr>
                  {request.instalments.map((instalment, index) => (
                    <Tr key={index}>
                      <Td>
                        {instalment.amountCurrency}{" "}
                        {formatCurrency(instalment.amount)}
                      </Td>
                      <Td>
                        <Icon w="9px" h="14px" as={FaCalendarAlt} />{" "}
                        {new Date(instalment.dueDate).toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )}
                      </Td>
                      <Td>{instalment.status}</Td>
                    </Tr>
                  ))}
                  <Tr>
                    <Td>
                      {request.instalments?.length > 0 && (
                        <HStack
                          w="full"
                          borderBottom="1px solid"
                          borderColor="gray.200"
                        ></HStack>
                      )}
                    </Td>
                    <Td>
                      {request.instalments?.length > 0 && (
                        <HStack
                          w="full"
                          borderBottom="1px solid"
                          borderColor="gray.200"
                        ></HStack>
                      )}
                    </Td>
                    <Td>
                      {request.instalments?.length > 0 && (
                        <HStack
                          w="full"
                          borderBottom="1px solid"
                          borderColor="gray.200"
                        ></HStack>
                      )}
                    </Td>
                  </Tr>
                  <div style={{ minHeight: "20px" }}>
                    {errorMessages[request.id] && (
                      <Text color="red.500">{errorMessages[request.id]}</Text>
                    )}
                  </div>
                </Tbody>
              </Table>
            </VStack>

            <Flex alignItems="center">
              <Text mr={2}>Status: </Text>
              <Select
                value={request.status || ""}
                onChange={(e) => handleStatusChange(request, e.target.value)}
              >
                {request.type === "PAY_NOW"
                  ? Object.values(StatusEnum)
                      .filter((status) => payNowStatuses.includes(status))
                      .map((status) => (
                        <option key={status} value={status}>
                          {status.replace("_", " ")}
                        </option>
                      ))
                  : Object.values(StatusEnum).map((status) => (
                      <option key={status} value={status}>
                        {status.replace("_", " ")}
                      </option>
                    ))}
              </Select>
            </Flex>
            <Box mt={2} style={{ minHeight: "20px" }}>
              {errorMessages[request.id] && (
                <Text color="red.500">{errorMessages[request.id]}</Text>
              )}
            </Box>
          </Box>

          {/* Items section */}
          <Box w="100%" mb={4}>
            <Text mb={2}>Order:</Text>
            {request.items.map((item) => (
              <Box key={item.name} mb={2}>
                <Text>
                  - {item.name}: {formatCurrency(item.quantity)} *{" "}
                  {request.amountCurrency} {formatCurrency(item.unitPrice)} ={" "}
                  {request.amountCurrency} {formatCurrency(item.total)}
                </Text>
              </Box>
            ))}
            {request.directPaymentId && <Text>USD payment</Text>}
            <Box
              onClick={() => handleOpenModal(request)} // New box to open the modal with payment details
              mr={2} // Margin to separate the buttons
              cursor="pointer" // Change cursor to pointer on hover
              display="flex" // Use flex to align the text and icon
              alignItems="center" // Align items to the center vertically
            >
              <Text fontSize="sm" color="grey">
                View Details
              </Text>
            </Box>
            {request.items.length > 0 && (
              <Box
                onClick={() => handlePurchaseOrderDownload(request)} // New box to open the modal with payment details
                mr={2} // Margin to separate the buttons
                cursor="pointer" // Change cursor to pointer on hover
                display="flex" // Use flex to align the text and icon
                alignItems="center" // Align items to the center vertically
              >
                <Text fontSize="sm" color="grey">
                  {loadingStates[request.id] ? "Downloading..." : "Download PO"}
                </Text>
              </Box>
            )}
            {request.files?.find(
              (f: any) => f.type === TypeEnum.ProofOfUserPayment
            ) && (
              <Box
                onClick={() =>
                  downloadFile(
                    request.files?.find(
                      (f: any) => f.type === TypeEnum.ProofOfUserPayment
                    ).key,
                    request.id
                  )
                } // New box to open the modal with payment details
                mr={2} // Margin to separate the buttons
                cursor="pointer" // Change cursor to pointer on hover
                display="flex" // Use flex to align the text and icon
                alignItems="center" // Align items to the center vertically
              >
                <Text fontSize="sm" color="grey">
                  {loadingStates[request.id]
                    ? "Downloading..."
                    : "Download proof"}
                </Text>
              </Box>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
}
