import {
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Text,
  Box,
  Flex,
  Select,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getCompany, updateCompany } from "../../../services/company";
import { CheckIcon } from "@chakra-ui/icons";
import { CompanyDto } from "../../../generated-client/model/company-dto";
import { GetStarted } from "./components/GetStarted";
import { getUser, updateUser } from "../../../services/user";

export default function CompanyInfoPage() {
  const SAVE_STATES = {
    IDLE: "idle",
    SAVING: "saving",
    SUCCESS: "success",
    ERROR: "error",
  };
  const [saveState, setSaveState] = useState(SAVE_STATES.IDLE);
  const [companyData, setCompanyData] = useState<CompanyDto>();
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState<CompanyDto.CountryEnum>("Rwanda");
  const [companyRegistrationNumber, setCompanyRegistrationNumber] =
    useState("");
  const [getStartedSeen, setGetStartedSeen] = useState<boolean>(false);
  const [isCompanyInfoPrefilled, setIsCompanyInfoPrefilled] =
    useState<boolean>(false);
  const [validationError, setValidationError] = useState("");

  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  let isCompanyInfoComplete =
    companyAddress && companyName && companyRegistrationNumber;

  async function fetchData() {
    try {
      const accessToken = await getAccessTokenSilently();
      const data = await getCompany(accessToken);
      const dataUser = await getUser(accessToken);
      setCompanyData(data.company);
      // console.log(data);
      setCompanyAddress(data.company.address);
      setEmail(dataUser.user.email);
      setPhoneNumber(dataUser.user.phoneNumber);
      setCompanyAddress(data.company.address);
      setCompanyName(data.company.name);
      setCountry(data.company.country);
      setCompanyRegistrationNumber(data.company.registrationNumber);
      if (
        data.company.address &&
        data.company.name &&
        data.company.registrationNumber
      ) {
        setIsCompanyInfoPrefilled(true);
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  }

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isAuthenticated) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  async function handleSave() {
    try {
      // Validation
      if (!isCompanyInfoComplete) {
        setValidationError(
          "All fields are mandatory. Please fill them out before saving."
        );
        return;
      } else {
        setValidationError(""); // Clear any previous error message if all fields are filled.
      }

      setSaveState(SAVE_STATES.SAVING);
      const accessToken = await getAccessTokenSilently();
      await updateUser(accessToken, email, phoneNumber);
      await updateCompany(accessToken, {
        name: companyName,
        country: country,
        address: companyAddress,
        registrationNumber: companyRegistrationNumber,
      });
      setSaveState(SAVE_STATES.SUCCESS);

      setTimeout(() => {
        setSaveState(SAVE_STATES.IDLE);
        window.location.href = "/dashboard/default";
      }, 2000);
    } catch (error) {
      console.error("Error updating company:", error);
      setSaveState(SAVE_STATES.ERROR);
      setTimeout(() => {
        setSaveState(SAVE_STATES.IDLE);
      }, 2000);
    }
  }

  if (!companyData) {
    return <div>Loading...</div>; // or return a loading spinner component
  }

  if (
    companyData?.creditInformation?.creditScore > 1 &&
    !getStartedSeen &&
    !isCompanyInfoComplete
  ) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card
          w={{ base: "100%", md: "50%" }}
          mb={{ base: "0px", "2xl": "20px" }}
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          minH="365px"
          pe="20px"
        >
          <GetStarted
            onContinue={() => {
              setGetStartedSeen(true);
            }}
          />
        </Card>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        w={{ base: "100%", md: "50%" }}
        mb={{ base: "0px", "2xl": "20px" }}
        gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
        minH="365px"
        pe="20px"
      >
        <Text fontSize="xl" fontWeight="bold" mb={4}>
          {isCompanyInfoPrefilled
            ? "Update Company Information"
            : "One more step"}
        </Text>
        <Text mb={4}>
          {isCompanyInfoPrefilled
            ? "Please fill the form, so we could learn more about your business. All fields are mandatory."
            : "To open your account we need you to provide some additional information on your business."}
        </Text>

        <SimpleGrid columns={1} spacing={4}>
          <FormControl isRequired>
            <FormLabel>Company Name</FormLabel>
            <Input
              placeholder="Enter company name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Country</FormLabel>
            <Select
              value={country}
              w={["100%", "100%", "80%", "35%"]}
              onChange={(e) =>
                setCountry(e.target.value as CompanyDto.CountryEnum)
              }
              isDisabled={isCompanyInfoPrefilled}
            >
              {Object.entries(CompanyDto.CountryEnum).map(([key, value]) => (
                <option key={key} value={value}>
                  {value}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Company Address</FormLabel>
            <Input
              placeholder="Enter company address"
              value={companyAddress}
              onChange={(e) => setCompanyAddress(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Company Registration Number</FormLabel>
            <Input
              placeholder="Enter company registration number"
              value={companyRegistrationNumber}
              onChange={(e) => setCompanyRegistrationNumber(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Phone number (including country code)</FormLabel>
            <Input
              placeholder="Enter your phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </FormControl>
        </SimpleGrid>

        <Flex
          justifyContent="flex-end"
          mt={4}
          flexDirection="column"
          alignItems="flex-end"
        >
          {validationError && (
            <Text color="red.500" mb={2}>
              {validationError}
            </Text>
          )}
          <Button
            colorScheme={saveState === SAVE_STATES.SUCCESS ? "green" : "blue"}
            onClick={handleSave}
            isLoading={saveState === SAVE_STATES.SAVING}
            rightIcon={saveState === SAVE_STATES.SUCCESS ? <CheckIcon /> : null}
          >
            {saveState === SAVE_STATES.SUCCESS ? "Saved" : "Save"}
          </Button>
        </Flex>
      </Card>
    </Box>
  );
}
