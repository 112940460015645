/**
 * Yolu API
 * Yolu backend API description 
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileDto } from './file-dto';
import { ItemDto } from './item-dto';
import { InstalmentDto } from './instalment-dto';


export interface PaymentRequestDto { 
    id: string;
    amount: number;
    amountCurrency: PaymentRequestDto.AmountCurrencyEnum;
    amountInSecondaryCurrency: number;
    amountInSecondaryCurrencyCurrency: PaymentRequestDto.AmountInSecondaryCurrencyCurrencyEnum;
    amountCredit: number;
    amountCreditCurrency: PaymentRequestDto.AmountCreditCurrencyEnum;
    amountToRepay: number;
    amountToRepayCurrency: PaymentRequestDto.AmountToRepayCurrencyEnum;
    interestRate: number;
    durationInDays: number;
    supplierName: string;
    supplierId: string;
    dueDate: string;
    termsAccepted: boolean;
    items?: Array<ItemDto>;
    instalments?: Array<InstalmentDto>;
    status: PaymentRequestDto.StatusEnum;
    type: PaymentRequestDto.TypeEnum;
    paymentMethod: PaymentRequestDto.PaymentMethodEnum;
    companyId: string;
    userId: string;
    directPaymentId?: string;
    files?: Array<FileDto>;
    lateRepaymentDate?: string;
    daysLate?: number;
    lateFee?: number;
    lateFeeApplied?: boolean;
    createdAt: string;
    updatedAt: string;
}
export namespace PaymentRequestDto {
    export type AmountCurrencyEnum = 'USD' | 'KES' | 'KES_SUPPLIER' | 'KES_OTHER' | 'RWF' | 'AED' | 'CNY';
    export const AmountCurrencyEnum = {
        Usd: 'USD' as AmountCurrencyEnum,
        Kes: 'KES' as AmountCurrencyEnum,
        KesSupplier: 'KES_SUPPLIER' as AmountCurrencyEnum,
        KesOther: 'KES_OTHER' as AmountCurrencyEnum,
        Rwf: 'RWF' as AmountCurrencyEnum,
        Aed: 'AED' as AmountCurrencyEnum,
        Cny: 'CNY' as AmountCurrencyEnum
    };
    export type AmountInSecondaryCurrencyCurrencyEnum = 'USD' | 'KES' | 'KES_SUPPLIER' | 'KES_OTHER' | 'RWF' | 'AED' | 'CNY';
    export const AmountInSecondaryCurrencyCurrencyEnum = {
        Usd: 'USD' as AmountInSecondaryCurrencyCurrencyEnum,
        Kes: 'KES' as AmountInSecondaryCurrencyCurrencyEnum,
        KesSupplier: 'KES_SUPPLIER' as AmountInSecondaryCurrencyCurrencyEnum,
        KesOther: 'KES_OTHER' as AmountInSecondaryCurrencyCurrencyEnum,
        Rwf: 'RWF' as AmountInSecondaryCurrencyCurrencyEnum,
        Aed: 'AED' as AmountInSecondaryCurrencyCurrencyEnum,
        Cny: 'CNY' as AmountInSecondaryCurrencyCurrencyEnum
    };
    export type AmountCreditCurrencyEnum = 'USD' | 'KES' | 'KES_SUPPLIER' | 'KES_OTHER' | 'RWF' | 'AED' | 'CNY';
    export const AmountCreditCurrencyEnum = {
        Usd: 'USD' as AmountCreditCurrencyEnum,
        Kes: 'KES' as AmountCreditCurrencyEnum,
        KesSupplier: 'KES_SUPPLIER' as AmountCreditCurrencyEnum,
        KesOther: 'KES_OTHER' as AmountCreditCurrencyEnum,
        Rwf: 'RWF' as AmountCreditCurrencyEnum,
        Aed: 'AED' as AmountCreditCurrencyEnum,
        Cny: 'CNY' as AmountCreditCurrencyEnum
    };
    export type AmountToRepayCurrencyEnum = 'USD' | 'KES' | 'KES_SUPPLIER' | 'KES_OTHER' | 'RWF' | 'AED' | 'CNY';
    export const AmountToRepayCurrencyEnum = {
        Usd: 'USD' as AmountToRepayCurrencyEnum,
        Kes: 'KES' as AmountToRepayCurrencyEnum,
        KesSupplier: 'KES_SUPPLIER' as AmountToRepayCurrencyEnum,
        KesOther: 'KES_OTHER' as AmountToRepayCurrencyEnum,
        Rwf: 'RWF' as AmountToRepayCurrencyEnum,
        Aed: 'AED' as AmountToRepayCurrencyEnum,
        Cny: 'CNY' as AmountToRepayCurrencyEnum
    };
    export type StatusEnum = 'PENDING' | 'APPROVED' | 'DECLINED' | 'PAST_DUE' | 'PAYMENT_SUBMITTED' | 'INVOICE_SENT' | 'REPAID' | 'REPAID_LATE';
    export const StatusEnum = {
        Pending: 'PENDING' as StatusEnum,
        Approved: 'APPROVED' as StatusEnum,
        Declined: 'DECLINED' as StatusEnum,
        PastDue: 'PAST_DUE' as StatusEnum,
        PaymentSubmitted: 'PAYMENT_SUBMITTED' as StatusEnum,
        InvoiceSent: 'INVOICE_SENT' as StatusEnum,
        Repaid: 'REPAID' as StatusEnum,
        RepaidLate: 'REPAID_LATE' as StatusEnum
    };
    export type TypeEnum = 'PAY_NOW' | 'FLEX_PAY';
    export const TypeEnum = {
        PayNow: 'PAY_NOW' as TypeEnum,
        FlexPay: 'FLEX_PAY' as TypeEnum
    };
    export type PaymentMethodEnum = 'TRANSFER' | 'ON_DELIVERY' | 'CREDIT' | 'NOT_AVAILABLE';
    export const PaymentMethodEnum = {
        Transfer: 'TRANSFER' as PaymentMethodEnum,
        OnDelivery: 'ON_DELIVERY' as PaymentMethodEnum,
        Credit: 'CREDIT' as PaymentMethodEnum,
        NotAvailable: 'NOT_AVAILABLE' as PaymentMethodEnum
    };
}


