import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getAllPaymentRequestsAdmin } from "../../../../services/payment-request";
import { PaymentRequestDtoWithOtherData } from "../../../../generated-client/model/payment-request-dto-with-other-data";
import { GetAllPaymentRequestsRequest } from "../../../../generated-client/model/get-all-payment-requests-request";
import CountryEnum = GetAllPaymentRequestsRequest.CountryEnum;

export const useFetchPaymentRequests = () => {
  const [paymentRequests, setPaymentRequests] = useState<
    PaymentRequestDtoWithOtherData[]
  >([]);
  const [accessToken, setAccessToken] = useState<string>("");
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const fetchData = async (
    skip = 0,
    take = 20,
    searchMinAmount: number = 0,
    searchMaxAmount: number = 100000000000,
    companyNameSearch: string = undefined,
    country: CountryEnum = null
  ) => {
    try {
      const accessToken = await getAccessTokenSilently();
      setAccessToken(accessToken);
      const data = await getAllPaymentRequestsAdmin(accessToken, {
        skip,
        take,
        amountFrom: searchMinAmount,
        amountTo: searchMaxAmount,
        companyNameSearch,
        country,
      });
      setPaymentRequests(data.paymentRequests);
    } catch (error) {
      console.error("Error fetching payment requests:", error);
    }
  };

  useEffect(() => {
    if (isLoading || !isAuthenticated) return;

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  return { paymentRequests, accessToken, fetchData };
};
