import { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  VStack,
  Input,
  FormControl,
  FormLabel,
  Link,
  Flex,
  useToast,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { createPaymentRequest } from "services/payment-request";
import { uploadFile } from "services/file";
import { attachProofOfUserPayment } from "services/payment-request";
import { CreatePaymentRequestRequest } from "generated-client/model/create-payment-request-request";
import { triggerMobilePayRequest } from "lib/utilities";

interface LocationState {
  totalAmount: number;
  orderAmount: number;
  shippingCost: number;
  cart: any[];
}

const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isMobilePayLoading, setIsMobilePayLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedOtherPaymentMethod, setSelectedOtherPaymentMethod] =
    useState<string>("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("+250");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvc, setCvc] = useState("");
  const [error, setError] = useState<{
    mobileNumber?: string;
    cardNumber?: string;
    expiryDate?: string;
    cvc?: string;
  }>({});

  const state = location.state as LocationState;
  const { totalAmount, orderAmount, shippingCost, cart } = state || {};

  const creditLimit = "10,000,000";

  const handleSubmitBankTransfer = async () => {
    setIsLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();

      await createPaymentRequest(accessToken, {
        amount: totalAmount,
        amountInSecondaryCurrency: 0,
        supplierId: cart[0]?.supplier.id,
        amountCurrency: CreatePaymentRequestRequest.AmountCurrencyEnum.Rwf,
        amountInSecondaryCurrencyCurrency:
          CreatePaymentRequestRequest.AmountCurrencyEnum.Rwf,
        amountCredit: 0,
        durationInDays: 0,
        type: CreatePaymentRequestRequest.TypeEnum.PayNow,
        paymentMethod: CreatePaymentRequestRequest.PaymentMethodEnum.Transfer,
        items: cart.map((item) => ({
          name: item.name,
          quantity: item.quantity,
          unitPrice: item.price,
          total: item.quantity * item.price,
        })),
        supplierName: cart[0]?.supplier.friendlyName || "",
        termsAccepted: true,
      });

      navigate("/dashboard/myPaymentRequests");
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to process payment request",
        status: "error",
        duration: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (file: File) => {
    try {
      setIsLoading(true);
      const accessToken = await getAccessTokenSilently();

      // First create the payment request
      const response = await createPaymentRequest(accessToken, {
        amount: totalAmount,
        amountInSecondaryCurrency: 0,
        supplierId: cart[0]?.supplier.id,
        amountCurrency: CreatePaymentRequestRequest.AmountCurrencyEnum.Rwf,
        amountInSecondaryCurrencyCurrency:
          CreatePaymentRequestRequest.AmountCurrencyEnum.Rwf,
        amountCredit: 0,
        durationInDays: 0,
        type: CreatePaymentRequestRequest.TypeEnum.PayNow,
        paymentMethod: CreatePaymentRequestRequest.PaymentMethodEnum.Transfer,
        items: cart.map((item) => ({
          name: item.name,
          quantity: item.quantity,
          unitPrice: item.price,
          total: item.quantity * item.price,
        })),
        supplierName: cart[0]?.supplier.friendlyName || "",
        termsAccepted: true,
      });

      // Then upload the file
      const fileUploaded = await uploadFile(
        accessToken,
        {
          paymentRequestId: response.paymentRequestId,
          type: "PROOF_OF_USER_PAYMENT",
        },
        file
      );

      // Attach the proof of payment
      await attachProofOfUserPayment(accessToken, {
        id: response.paymentRequestId,
        fileId: fileUploaded.id,
      });

      setIsFileUploaded(true);
      toast({
        title: "Success",
        description: "Proof of payment uploaded successfully",
        status: "success",
        duration: 5000,
      });

      navigate("/dashboard/myPaymentRequests");
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to upload proof of payment",
        status: "error",
        duration: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  const handleMobilePayRequest = async () => {
    if (!isValidMobileNumber()) return;

    setIsMobilePayLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      await triggerMobilePayRequest({
        accessToken,
        amount: Math.ceil(totalAmount * 1.0025), // Including mobile money fee
        currency: "RWF",
        phoneNumber: mobileNumber,
        supplierId: cart[0].supplier.id,
      });

      // Create payment request after mobile payment
      await createPaymentRequest(accessToken, {
        amount: totalAmount,
        amountInSecondaryCurrency: 0,
        supplierId: cart[0]?.supplier.id,
        amountCurrency: CreatePaymentRequestRequest.AmountCurrencyEnum.Rwf,
        amountInSecondaryCurrencyCurrency:
          CreatePaymentRequestRequest.AmountCurrencyEnum.Rwf,
        amountCredit: 0,
        durationInDays: 0,
        type: CreatePaymentRequestRequest.TypeEnum.PayNow,
        paymentMethod: CreatePaymentRequestRequest.PaymentMethodEnum.OnDelivery,
        items: cart.map((item) => ({
          name: item.name,
          quantity: item.quantity,
          unitPrice: item.price,
          total: item.quantity * item.price,
        })),
        supplierName: cart[0]?.supplier.friendlyName || "",
        termsAccepted: true,
      });

      navigate("/dashboard/myPaymentRequests");
    } catch (error: any) {
      if (error?.response?.data?.statusCode === 500) {
        toast({
          title: "Payment Error",
          description: "Mobile payment service is currently unavailable",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: error?.response?.data?.message || "An error occurred",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } finally {
      setIsMobilePayLoading(false);
    }
  };

  const isValidMobileNumber = (): boolean => {
    if (!mobileNumber) {
      setError({ mobileNumber: "Please provide a mobile number" });
      return false;
    }
    if (mobileNumber.length < 12) {
      setError({ mobileNumber: "Mobile number must be 12 digits" });
      return false;
    }
    setError({});
    return true;
  };

  const handleCardPayment = () => {
    console.log("Card payment selected");
  };

  if (!totalAmount || !cart) {
    navigate("/shop");
    return null;
  }

  return (
    <Box bg="white" minH="100vh" p={8}>
      <Box maxW="1200px" mx="auto">
        <Flex gap={16}>
          <Box flex={1}>
            <Text fontSize="2xl" fontWeight="bold" mb={6}>
              Payment
            </Text>
            <Text mb={4}>Select payment method</Text>

            <Tabs onChange={setSelectedTab} colorScheme="black">
              <TabList>
                <Tab>Bank Transfer</Tab>
                <Tab>Mobile Money</Tab>
                <Tab>Other Payment Methods</Tab>
              </TabList>

              <TabPanels>
                {/* Bank Transfer Panel */}
                <TabPanel>
                  <VStack
                    align="start"
                    spacing={4}
                    p={4}
                    border="1px"
                    borderColor="gray.200"
                    borderRadius="md"
                  >
                    <Text>Account name: {cart[0]?.supplier?.name}</Text>
                    <Text>
                      Account number: {cart[0]?.supplier?.accountNumber}
                    </Text>
                    <Text>Bank: {cart[0]?.supplier?.bankName}</Text>

                    <Text fontSize="sm" mt={4}>
                      Upload proof of payment after you've made the payment.
                    </Text>

                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      accept="image/*, .pdf, .docx, .jpg, .png, .jpeg"
                    />

                    <Flex gap={4}>
                      <Button
                        onClick={handleSubmitBankTransfer}
                        isLoading={isLoading}
                      >
                        Continue
                      </Button>
                      <Button
                        colorScheme="blue"
                        onClick={triggerFileInput}
                        isLoading={isLoading}
                      >
                        {isFileUploaded ? "✅" : ""} Upload proof of payment
                      </Button>
                    </Flex>
                  </VStack>
                </TabPanel>

                {/* Mobile Money Panel */}
                <TabPanel>
                  <VStack
                    align="start"
                    spacing={4}
                    p={4}
                    border="1px"
                    borderColor="gray.200"
                    borderRadius="md"
                  >
                    <FormControl isInvalid={!!error.mobileNumber}>
                      <FormLabel>Enter your mobile number</FormLabel>
                      <Input
                        type="tel"
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        placeholder="+250789123456"
                      />
                      {error.mobileNumber && (
                        <Text color="red.500" fontSize="sm" mt={1}>
                          {error.mobileNumber}
                        </Text>
                      )}
                    </FormControl>

                    <Text fontSize="sm" color="gray.600">
                      You will receive a prompt on your phone to complete the
                      payment.
                    </Text>

                    <Button
                      colorScheme="blue"
                      onClick={handleMobilePayRequest}
                      isLoading={isMobilePayLoading}
                    >
                      Submit
                    </Button>
                  </VStack>
                </TabPanel>

                {/* Other Payment Methods Panel */}
                <TabPanel>
                  <RadioGroup
                    onChange={setSelectedOtherPaymentMethod}
                    value={selectedOtherPaymentMethod}
                  >
                    <Stack spacing={4}>
                      {/* Pay Later Option */}
                      <Box
                        p={4}
                        border="1px"
                        borderColor="gray.200"
                        borderRadius="md"
                        cursor="pointer"
                        _hover={{ borderColor: "gray.300" }}
                      >
                        <Radio value="payLater">
                          Pay later | Credit limit: NGN {creditLimit}
                        </Radio>
                      </Box>

                      {/* Bank Transfer Option */}
                      <Box
                        p={4}
                        border="1px"
                        borderColor="gray.200"
                        borderRadius="md"
                        cursor="pointer"
                        _hover={{ borderColor: "gray.300" }}
                      >
                        <Radio value="bankTransfer">Pay by bank transfer</Radio>
                      </Box>

                      {/* Card Payment Option */}
                      <Box
                        p={4}
                        border="1px"
                        borderColor="gray.200"
                        borderRadius="md"
                        cursor="pointer"
                        _hover={{ borderColor: "gray.300" }}
                      >
                        <Radio value="card">Pay by card</Radio>
                        {selectedOtherPaymentMethod === "card" && (
                          <VStack align="start" spacing={4} mt={4}>
                            <FormControl>
                              <FormLabel>Card number</FormLabel>
                              <Input
                                placeholder="1234 1234 1234 1234"
                                value={cardNumber}
                                onChange={(e) => setCardNumber(e.target.value)}
                              />
                            </FormControl>
                            <Flex gap={4} width="100%">
                              <FormControl>
                                <FormLabel>Expiration date</FormLabel>
                                <Input
                                  placeholder="MM/YY"
                                  value={expiryDate}
                                  onChange={(e) =>
                                    setExpiryDate(e.target.value)
                                  }
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel>Security code</FormLabel>
                                <Input
                                  placeholder="CVC"
                                  value={cvc}
                                  onChange={(e) => setCvc(e.target.value)}
                                />
                              </FormControl>
                            </Flex>
                          </VStack>
                        )}
                      </Box>
                    </Stack>
                  </RadioGroup>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>

          {/* Order Summary */}
          <Box w="400px">
            <Text fontSize="xl" fontWeight="bold" mb={4}>
              Your order
            </Text>
            <VStack align="stretch" spacing={3}>
              <Flex justify="space-between">
                <Text>Order amount</Text>
                <Text>NGN {orderAmount.toLocaleString()}</Text>
              </Flex>
              <Flex justify="space-between">
                <Text>Shipping estimate</Text>
                <Text>NGN {shippingCost.toLocaleString()}</Text>
              </Flex>
              <Flex justify="space-between" fontWeight="bold">
                <Text>Total amount</Text>
                <Text>NGN {totalAmount.toLocaleString()}</Text>
              </Flex>
              <Link
                color="blue.500"
                href="#"
                onClick={() => window.open("/invoice", "_blank")}
              >
                View invoice
              </Link>
            </VStack>
          </Box>
        </Flex>

        {/* Submit Button for Other Payment Methods */}
        {selectedTab === 2 && selectedOtherPaymentMethod && (
          <Flex mt={8}>
            <Button
              size="lg"
              width="200px"
              colorScheme="black"
              bg="black"
              color="white"
              isLoading={isLoading}
              onClick={() => {
                switch (selectedOtherPaymentMethod) {
                  case "bankTransfer":
                    handleSubmitBankTransfer();
                    break;
                  case "card":
                    handleCardPayment();
                    break;
                  case "payLater":
                    // Handle pay later logic
                    break;
                }
              }}
            >
              Place order
            </Button>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default PaymentPage;
