import { callGet, callUpdate } from "../../lib/api-private";
import { GetExchangeRateResponse } from "../../generated-client/model/get-exchange-rate-response";
import { GetAllExchangeRatesResponse } from "../../generated-client/model/get-all-exchange-rates-response";
import { UpdateExchangeRateRequest } from "../../generated-client/model/update-exchange-rate-request";

export const getExchangeRates = async (
  accessToken: string
): Promise<GetAllExchangeRatesResponse> => {
  const data = await callGet(accessToken, "/finance/exchange-rate/all");
  return data;
};

export const getExchangeRate = async (
  accessToken: string,
  from: string,
  to: string
): Promise<GetExchangeRateResponse> => {
  const data = await callGet(
    accessToken,
    "/finance/exchange-rate/" + from + "/" + to
  );
  return data;
};

export const updateExchangeRateAdmin = async (
  accessToken: string,
  data: UpdateExchangeRateRequest
): Promise<void> => {
  const response = await callUpdate(
    accessToken,
    "/finance/exchange-rate/",
    data
  );
  return response;
};
