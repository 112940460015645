import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card";

import { SourcingRequestDto } from "generated-client/model/sourcing-request-dto";
import { SourcingItemDto } from "generated-client/model/sourcing-item-dto";
import { SourcingRequestProFormaInvoiceDto } from "generated-client/model/sourcing-request-pro-forma-invoice-dto";
import { downloadProFormaInvoice } from "../../../../services/sourcing";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

interface TableComponentProps {
  sourcingRequests: SourcingRequestDto[];
  handleStatusChange: (sourcingRequestId: string, newStatus: string) => void;
  handleOpenOrderDetailModal: (
    value: boolean,
    items: SourcingItemDto[]
  ) => void;
  handleOpenProformaInvoice: (sourcingRequest: SourcingRequestDto) => void;
  handleOpenProformaInvoiceDetail: (
    proformaInvoice: SourcingRequestProFormaInvoiceDto
  ) => void;
}

export default function TableComponent({
  sourcingRequests,
  handleStatusChange,
  handleOpenOrderDetailModal,
  handleOpenProformaInvoice,
}: TableComponentProps) {
  const [loadingStates, setLoadingStates] = useState<{
    [key: string]: boolean;
  }>({});
  const { getAccessTokenSilently } = useAuth0();

  const setRowLoading = (id: string, isLoading: boolean) => {
    setLoadingStates((prev) => ({ ...prev, [id]: isLoading }));
  };
  const handleProFormaInvoiceDownload = async (
    sourcingRequestId: string,
    proFormaInvoiceId: string
  ) => {
    setRowLoading(proFormaInvoiceId, true);
    const accessToken = await getAccessTokenSilently();
    await downloadProFormaInvoice(
      sourcingRequestId,
      proFormaInvoiceId,
      accessToken
    );
    setRowLoading(proFormaInvoiceId, false);
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        w={{ base: "100%", md: "100%" }}
        mb={{ base: "0px", xl: "20px" }}
        minH="365px"
        pe="20px"
      >
        <Table variant="simple" size="md" fontSize="0.9em">
          <Thead>
            <Tr>
              <Th>Company</Th>
              <Th>Country</Th>
              <Th>Request Date</Th>
              <Th>Request</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {sourcingRequests?.map((sourcingRequest) => (
              <Tr key={sourcingRequest.id}>
                <Td>
                  <Text>{sourcingRequest?.company?.name}</Text>
                </Td>
                <Td>
                  <Text>{sourcingRequest?.company?.country}</Text>
                </Td>
                <Td>
                  <Text>
                    {new Date(sourcingRequest.createdAt).toLocaleDateString(
                      undefined,
                      {
                        year: "numeric",
                        month: "long",
                      }
                    )}
                  </Text>
                </Td>
                <Td>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    onClick={() =>
                      handleOpenOrderDetailModal(true, sourcingRequest.items)
                    }
                  >
                    View order
                  </Button>
                </Td>
                <Td>
                  <select
                    value={sourcingRequest.status}
                    onChange={(e) =>
                      handleStatusChange(sourcingRequest.id, e.target.value)
                    }
                  >
                    {Object.values(SourcingRequestDto.StatusEnum).map(
                      (status) => (
                        <option key={status} value={status}>
                          {status}
                        </option>
                      )
                    )}
                  </select>
                </Td>
                <Td width="300px">
                  <Flex wrap={"wrap"} gap={2}>
                    {sourcingRequest?.proFormaInvoices?.length
                      ? sourcingRequest?.proFormaInvoices.map((item, index) => {
                          return (
                            <Button
                              key={index}
                              colorScheme="blackAlpha"
                              size="sm"
                              isLoading={loadingStates[item.id]}
                              onClick={() =>
                                handleProFormaInvoiceDownload(
                                  sourcingRequest.id,
                                  item.id
                                )
                              }
                            >
                              Invoice {index + 1}
                            </Button>
                          );
                        })
                      : null}
                    <Button
                      colorScheme="blue"
                      size="sm"
                      onClick={() => handleOpenProformaInvoice(sourcingRequest)}
                    >
                      Create invoice
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
    </Box>
  );
}
