import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  Input,
  FormControl,
  FormLabel,
  Flex,
  Icon,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  Divider,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { updateCompany } from "../../../../../services/company";
import {
  formatCurrency,
  triggerMobilePayRequest,
} from "../../../../../lib/utilities";
import { uploadFile } from "../../../../../services/file";

interface MobilePayModalProps {
  isOpen: boolean;
  onClose: () => void;
  paymentRequest: { amount: number; pricingPlanId: string };
  currency: string;
}

const PricingPayModal: React.FC<MobilePayModalProps> = ({
  currency,
  paymentRequest,
  onClose,
  isOpen,
}) => {
  const [payAmount, setPayAmount] = useState(paymentRequest.amount);
  const [mobileNumber, setMobileNumber] = useState("+250");
  const [buttonText] = useState("Submit");
  const [requestSent, setRequestSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTransferSubmitLoading, setIsTransferSubmitLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedPaymentMethodIndex, setSelectedPaymentMethodIndex] =
    useState(0);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();

  let fileInputRef = useRef(null);

  const handleConfirmClick = async (file?: File) => {
    setIsTransferSubmitLoading(true);
    const accessToken = await getAccessTokenSilently();
    if (file) {
      await uploadFile(
        accessToken,
        {
          type: "PROOF_OF_USER_SUBSCRIPTION_PAYMENT",
        },
        file
      );
    }

    setTimeout(() => {
      setIsTransferSubmitLoading(false);
      handleUpdate();
    }, 2000);
  };

  const handleFileUpload = async (file: File) => {
    await handleConfirmClick(file);
    setIsFileUploaded(true);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    handleFileUpload(file);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleUpdate = async () => {
    const accessToken = await getAccessTokenSilently();
    await updateCompany(accessToken, {
      pricingPlanId: paymentRequest.pricingPlanId,
      paymentMethodType: selectedPaymentMethodIndex === 1 ? "Bank" : "Mobile",
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleSubmit = async () => {
    setError("");
    if (payAmount <= 0) {
      setError("Amount must be greater than zero");
      return false;
    }
    if (mobileNumber.length < 13) {
      setError("Please check the phone number");
      return false;
    }

    setIsLoading(true);

    try {
      const accessToken = await getAccessTokenSilently();
      await triggerMobilePayRequest({
        accessToken,
        amount: payAmount,
        currency,
        phoneNumber: mobileNumber,
      });
      setRequestSent(true);
      setTimeout(() => {
        onClose();
        setRequestSent(false);
        setPayAmount(0);
        setIsLoading(false);
        handleUpdate();
      }, 4000);
    } catch (error: any) {
      if (error?.response?.data?.statusCode === 500) {
        toast({
          title: "Payment Error",
          description: t("mobile_pay_error"),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: error?.response?.data?.message
            ? error?.response?.data?.message
            : t("error_occurred"),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
    setIsLoading(false);
  };

  const handlePaymentMethodIndexChange = (index: number) => {
    setSelectedPaymentMethodIndex(index);
  };

  useEffect(() => {
    setPayAmount(paymentRequest.amount);
  }, [paymentRequest]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        {!requestSent && (
          <ModalHeader textAlign={"center"}>{t("payment")}</ModalHeader>
        )}
        <ModalBody>
          <Tabs
            w="100%"
            onChange={handlePaymentMethodIndexChange}
            index={selectedPaymentMethodIndex}
          >
            <TabList>
              <Tab>{t("mobile_money")}</Tab>
              <Tab>{t("bank_transfer")}</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Box
                  w="100%"
                  border={"1px"}
                  borderColor="gray.200"
                  borderRadius="md"
                  p={2}
                >
                  {!requestSent && (
                    <VStack spacing={4} align="left">
                      <Text textAlign="left">
                        Amount due: {currency}{" "}
                        {formatCurrency(paymentRequest.amount)}
                      </Text>
                      <FormControl id="amount">
                        <FormLabel>Amount to pay</FormLabel>
                        <Input type="number" value={payAmount} readOnly />
                      </FormControl>
                      <FormControl id="mobileNumber">
                        <FormLabel>Enter your mobile number</FormLabel>
                        <Input
                          type="tel"
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                        />
                      </FormControl>
                      <Text pt={"10px"} fontSize="sm">
                        {t("mobile_pay_disclaimer_1")}
                      </Text>

                      {error && (
                        <Text color="red.500" fontSize="md">
                          {error}
                        </Text>
                      )}
                    </VStack>
                  )}
                  {requestSent && (
                    <VStack spacing={4} align="center">
                      <Icon
                        as={CheckCircleIcon}
                        mt={"30px"}
                        boxSize={8}
                        color="green.500"
                      />
                      <Text textAlign="center">
                        Payment request sent to mobile number.
                        <br />
                        Once we receive your payment, status will be updated.
                      </Text>
                    </VStack>
                  )}
                </Box>
              </TabPanel>
              <TabPanel>
                <Box
                  w="100%"
                  border="1px"
                  borderColor="gray.200"
                  borderRadius="md"
                  p={2}
                >
                  <Text fontSize="md" fontWeight="medium" mb={1}>
                    Make your payment to one of the following accounts:
                  </Text>

                  <Divider />

                  <Text fontSize="sm" mt={4}>
                    {t("account_name")} GoYolu RWANDA LTD
                    <br />
                    {t("account_number")} 100145553029
                    <br />
                    {t("bank_name")} Bank of Kigali
                    <br />
                  </Text>

                  <Text fontSize="sm" mt={4}>
                    {t("account_name")} GoYolu RWANDA LTD
                    <br />
                    {t("account_number")} 20071655001
                    <br />
                    {t("bank_name")} I&M Bank (Rwanda) Plc
                    <br />
                  </Text>
                </Box>
              </TabPanel>
            </TabPanels>
            {selectedPaymentMethodIndex === 1 && (
              <Text fontSize="sm" pt={"10px"}>
                {t("upload_proof_after_payment")}
              </Text>
            )}
          </Tabs>
        </ModalBody>
        <ModalFooter>
          {!requestSent && selectedPaymentMethodIndex === 0 && (
            <Flex width="100%" justifyContent="flex-end">
              <Button
                variant="ghost"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
              <Button
                colorScheme="blue"
                isLoading={isLoading}
                onClick={handleSubmit}
              >
                {buttonText}
              </Button>
            </Flex>
          )}
          {!requestSent && selectedPaymentMethodIndex === 1 && (
            <>
              <input
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
                accept="image/*, .pdf, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .jpg, .png, .jpeg"
              />
              <Button
                onClick={triggerFileInput}
                disabled={isTransferSubmitLoading}
                isLoading={isTransferSubmitLoading}
                mr="3px"
                colorScheme="blue"
              >
                {isFileUploaded ? "✅" : ""} {t("upload_proof_of_payment")}
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PricingPayModal;
