import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  Icon,
  Flex,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons"; // Import the CheckCircleIcon

interface Props {
  isOpen: boolean;
  amount: number;
  currency: string;
  bankDetails: string[];
  onClose: () => void;
}

const ProofOfUserPaymentModal: React.FC<Props> = ({ onClose, isOpen }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="center">
            <Icon as={CheckCircleIcon} boxSize={8} color="green.500" />{" "}
            {/* Big green checkmark */}
            <Text textAlign="center">
              <b>Order submitted</b>
              <br />
            </Text>
            <Text textAlign="center">
              We will update you on the status of the delivery.
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="center">
            {" "}
            {/* Use Flex to center the button */}
            <Button colorScheme="blue" onClick={onClose}>
              Continue
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProofOfUserPaymentModal;
