import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  Icon,
  Flex,
  Table,
  Tbody,
  Tr,
  Td,
  FormLabel,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { formatCurrency } from "../../../../lib/utilities";
import { PaymentRequestDto } from "../../../../generated-client/model/payment-request-dto";
import { FaCalendarAlt } from "react-icons/fa";

interface FlexPayModalProps {
  isOpen: boolean;
  paymentRequest: PaymentRequestDto;
  onClose: () => void;
}

const FlexPayPaymentDetailsModal: React.FC<FlexPayModalProps> = ({
  onClose,
  paymentRequest,
  isOpen,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="center">
            <Text fontWeight="bold" textAlign="center">
              Flex pay repayment details
              <br />
            </Text>
            <VStack align="start" spacing={4}>
              <Stack spacing={1} w={["100%", "100%", "100%", "95%"]}>
                <Flex justify="space-between" mb={1}>
                  <FormLabel>Amount:</FormLabel>
                  <Text>
                    {paymentRequest.amountCurrency +
                      " " +
                      formatCurrency(paymentRequest.amount)}
                  </Text>
                </Flex>
                <Flex justify="space-between" mb={1}>
                  <FormLabel>Transaction fee: </FormLabel>
                  <Text>
                    {paymentRequest.amountToRepayCurrency +
                      " " +
                      formatCurrency(
                        paymentRequest.amountToRepay - paymentRequest.amount
                      )}
                  </Text>
                </Flex>
                <Flex justify="space-between" mb={1}>
                  <FormLabel>Total amount to repay: </FormLabel>
                  <Text>
                    {paymentRequest.amountToRepayCurrency +
                      " " +
                      formatCurrency(paymentRequest.amountToRepay)}
                  </Text>
                </Flex>
              </Stack>
              <Text fontWeight="bold" mb={2}>
                Repayment Schedule
              </Text>
              <VStack spacing="5px">
                <Table size="sm">
                  <Tbody>
                    {paymentRequest.instalments.map((instalment, index) => (
                      <Tr key={index}>
                        <Td>
                          <Icon w="9px" h="14px" as={FaCalendarAlt} />{" "}
                          {new Date(instalment.dueDate).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          )}
                        </Td>
                        <Td>
                          {instalment.amountCurrency}{" "}
                          {formatCurrency(instalment.amount)}
                        </Td>
                        <Td>{instalment.status}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </VStack>

              <Text>
                We will provide you the bank account details to make your
                repayments.
              </Text>
              {/*{bankDetails.map((detail, index) => (*/}
              {/*    <Text>{detail}</Text>*/}
              {/*))};*/}
            </VStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="center">
            {" "}
            {/* Use Flex to center the button */}
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FlexPayPaymentDetailsModal;
