import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { GetAllPaymentRequestsRequest } from "../../../../generated-client/model/get-all-payment-requests-request";
import CountryEnum = GetAllPaymentRequestsRequest.CountryEnum;
import { getAllDirectPaymentsAdmin } from "../../../../services/direct-payment";
import { DirectPaymentDto } from "../../../../generated-client/model/direct-payment-dto";

export const useFetchDirectPayments = () => {
  const [directPayments, setDirectPayments] = useState<DirectPaymentDto[]>([]);
  const [accessToken, setAccessToken] = useState<string>("");
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const fetchData = async (
    skip = 0,
    take = 20,
    companyNameSearch: string = undefined,
    country: CountryEnum = null
  ) => {
    try {
      const accessToken = await getAccessTokenSilently();
      setAccessToken(accessToken);
      const data = await getAllDirectPaymentsAdmin(accessToken, {
        skip,
        take,
        companyNameSearch,
        country,
      });
      setDirectPayments(data.directPayments);
    } catch (error) {
      console.error("Error fetching payment requests:", error);
    }
  };

  useEffect(() => {
    if (isLoading || !isAuthenticated) return;

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  return { directPayments, accessToken, fetchData };
};
