import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  Flex,
  Stack,
  RadioGroup,
  Radio,
  Box,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { formatCurrency } from "../../../../lib/utilities";
import { RepaymentTermsType } from "../index";
import { CreateDirectPaymentRequest } from "../../../../generated-client/model/create-direct-payment-request";

const flexPayBankDetails = [
  "Account Name: MAMLAKA HUB & SPOKE LIMITED",
  "Bank account number: 55010160018203",
  "Bank/Branch: UBA Kenya Bank Limited, Westland’s Branch",
  "Swift code: UNAFKENA",
];

const mPesaDetails = [
  "Paybill Number: 559900",
  "Account number: 55010160018203",
];

interface ConfirmationModalProps {
  isOpen: boolean;
  sendAmount: number;
  totalAmount: number;
  recipientCountry: string;
  recipientName: string;
  recipientAddress: string;
  accountNumber: string;
  currency: string;
  recipientSwiftCode: string;
  repaymentTerms: RepaymentTermsType | null;
  onClose: () => void;
  onConfirm: (
    paymentMethod: CreateDirectPaymentRequest.PaymentMethodEnum
  ) => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  sendAmount,
  totalAmount,
  recipientCountry,
  recipientAddress,
  recipientSwiftCode,
  recipientName,
  accountNumber,
  currency,
  repaymentTerms,
  onClose,
  onConfirm,
}) => {
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<CreateDirectPaymentRequest.PaymentMethodEnum>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleConfirmClick = async () => {
    setIsLoading(true);
    if (showPaymentMethod) {
      await onConfirm(selectedPaymentMethod);
    } else if (totalAmount === 0) {
      await onConfirm(
        CreateDirectPaymentRequest.PaymentMethodEnum.BankTransfer
      );
    } else {
      setShowPaymentMethod(true);
    }

    setIsLoading(false);
  };

  const handlePaymentMethodChange = (method: string) => {
    setSelectedPaymentMethod(
      method as CreateDirectPaymentRequest.PaymentMethodEnum
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {showPaymentMethod ? "Choose payment method" : "Review Payment"}
        </ModalHeader>
        <ModalBody>
          {showPaymentMethod ? (
            <VStack spacing={4}>
              <Text>
                To complete your transaction, please make your payment of{" "}
                <b>
                  {currency} {formatCurrency(totalAmount)}
                </b>
                .
              </Text>
              <Text>
                Select one of the options below for the payment account details.
                Your transaction will be processed once payment has been
                received.{" "}
              </Text>
              <RadioGroup
                w="100%"
                onChange={handlePaymentMethodChange}
                value={selectedPaymentMethod}
              >
                <Stack direction="column" alignItems="flex-start">
                  <Box
                    w="100%"
                    border="1px"
                    borderColor="gray.200"
                    borderRadius="md"
                    p={2}
                  >
                    <Radio
                      value={CreateDirectPaymentRequest.PaymentMethodEnum.Mpesa}
                    >
                      M-Pesa
                    </Radio>
                  </Box>
                  {selectedPaymentMethod ===
                    CreateDirectPaymentRequest.PaymentMethodEnum.Mpesa && (
                    <>
                      <Text fontSize="sm">
                        {mPesaDetails.map((detail, index) => (
                          <>
                            {detail}
                            <br />
                          </>
                        ))}
                      </Text>
                      <Text fontSize="sm">
                        💡 You can also view the account details in ‘My
                        transactions - International’
                      </Text>
                    </>
                  )}
                  <Box
                    w="100%"
                    border="1px"
                    borderColor="gray.200"
                    borderRadius="md"
                    p={2}
                  >
                    <Radio
                      value={
                        CreateDirectPaymentRequest.PaymentMethodEnum
                          .BankTransfer
                      }
                    >
                      Bank transfer
                    </Radio>
                  </Box>
                  {selectedPaymentMethod ===
                    CreateDirectPaymentRequest.PaymentMethodEnum
                      .BankTransfer && (
                    <>
                      <Text fontSize="sm">
                        {flexPayBankDetails.map((detail, index) => (
                          <>
                            {detail}
                            <br />
                          </>
                        ))}
                      </Text>
                      <Text fontSize="sm">
                        💡 You can also view the account details in ‘My
                        transactions - International’
                      </Text>
                    </>
                  )}
                </Stack>
              </RadioGroup>
            </VStack>
          ) : (
            <VStack align="start" spacing={2} minW={200}>
              <Text fontWeight="bold">Payment details</Text>
              <Stack spacing={1} width="100%">
                <Flex justifyContent="space-between">
                  <Text width="50%">Send amount:</Text>
                  <Text width="50%" flex="1" textAlign="left">
                    ${formatCurrency(sendAmount)}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text width="50%">Pay now:</Text>
                  <Text flex="1" textAlign="left">
                    {currency} {formatCurrency(totalAmount)}
                  </Text>
                </Flex>
              </Stack>
              {repaymentTerms && (
                <>
                  <Text fontWeight="bold">Flex pay amount to pay later</Text>
                  <Stack spacing={1} width="100%">
                    <Flex justifyContent="space-between">
                      <Text width="50%">Amount:</Text>
                      <Text width="50%" flex="1" textAlign="left">
                        {currency} {formatCurrency(repaymentTerms.creditAmount)}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text width="50%">Transaction fee:</Text>
                      <Text flex="1" textAlign="left">
                        {currency}{" "}
                        {formatCurrency(repaymentTerms.transactionFee)}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text width="50%">Total amount to repay:</Text>
                      <Text flex="1" textAlign="left">
                        {currency} {formatCurrency(repaymentTerms.totalRepay)}
                      </Text>
                    </Flex>
                  </Stack>
                </>
              )}
              <Text fontWeight="bold">Recipient details</Text>
              <Stack spacing={1} width="100%">
                <Flex justifyContent="space-between">
                  <Text width="50%">Country:</Text>
                  <Text width="50%" flex="1" textAlign="left">
                    {recipientCountry}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text width="50%">Name:</Text>
                  <Text width="50%" flex="1" textAlign="left">
                    {recipientName}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text width="50%">Address:</Text>
                  <Text width="50%" flex="1" textAlign="left">
                    {recipientAddress}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text width="50%">Account number:</Text>
                  <Text width="50%" flex="1" textAlign="left">
                    {accountNumber}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text width="50%">Bank swift code:</Text>
                  <Text width="50%" flex="1" textAlign="left">
                    {recipientSwiftCode}
                  </Text>
                </Flex>
              </Stack>
            </VStack>
          )}
          {showPaymentMethod &&
            selectedPaymentMethod ===
              CreateDirectPaymentRequest.PaymentMethodEnum.BankTransfer && (
              <Text pt="24px" fontSize="xs">
                We process our payments through our regulated partner Mamlaka
                Hub & Spoke Limited, a Payment Services Provider authorized by
                the Central Bank of Kenya.
              </Text>
            )}
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleConfirmClick}
            isLoading={isLoading}
          >
            {showPaymentMethod ? "Done" : "Confirm"}
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              onClose();
              setShowPaymentMethod(false);
              setSelectedPaymentMethod(null);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
